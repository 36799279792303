<template>
  <v-card flat class="pa-0 mt-1">
    <scroll-list v-if="renderScrollList" id="chemicallistScrollList" :payload="scrollListPayload"></scroll-list>
  </v-card>
</template>

<script>
import { hostAppApi } from '../../plugins/axios_settings'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      MODULE: '',
      scrollListPayload: {
        width: 60,
        loading: false,
        module: '',
        moduleTitle: 'message.layout.chemicallist',
        addBtnTo: '',
        showScanCode: true,
        editHandler: null,
        actionSize: '0', // for delete action
        list: [],
        totalCount: 0,
        itemHeaders: [
          'message.chemicallist.chemicalName',
          // 'message.chemicallist.provider',
          'message.chemicallist.repository'
          // 'message.deviation.responsible'
        ],
        items: [{
          value: 'name',
          class: 'pa-0 pa-1 mx-5 text-justify',
          cols: '5',
          spanClass: 'body pl-2',
          isParagraph: true
        },
        // {
        //   value: 'provider',
        //   class: 'pa-0 pa-1 text-justify',
        //   cols: '3',
        //   spanClass: 'subtitle'
        // },
        {
          value: 'repository',
          class: 'pa-0 pa-1 text-justify',
          cols: '6',
          spanClass: 'subtitle'
        }
        // {
        //   value: 'responsible',
        //   class: 'pa-0 pa-1 text-justify',
        //   cols: '3',
        //   spanClass: 'subtitle'
        // }
        ],
        hasDelete: false,
        listenerAdded: false,
        page: -1,
        previousSearch: '',
        recordsPerPage: 15
      },
      renderScrollList: false,
      listMaxCallLoaded: false
    }
  },
  computed: {
    ...mapGetters(['getHostRefApi', 'userId'])
  },
  components: {
    'scroll-list': () => import('@/components/VirtualScrollList.vue')
  },
  created () {
    this.MODULE = 'chemicallist'
    this.scrollListPayload.editHandler = (item) => this.$router.push(`/chemicallist/${item.id}`)
  },
  mounted () {
    this.getListHandler()
    // Load list on page
    this.$eventBus.$on('loadList', (search) => {
      this.getListHandler(search)
    })
  },
  methods: {
    getListHandler (search) {
      if (search && (search !== this.scrollListPayload.previousSearch)) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: search, totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (!search && this.scrollListPayload.previousSearch) {
        this.scrollListPayload = { ...this.scrollListPayload, ...{ list: [], page: -1, previousSearch: '', totalCount: 0 } }
        this.listMaxCallLoaded = false
      }
      if (this.listMaxCallLoaded) return
      this.scrollListPayload.loading = true
      this.scrollListPayload.module = this.MODULE
      this.scrollListPayload.page++
      const model = {
        start: (this.scrollListPayload.page * this.scrollListPayload.recordsPerPage),
        length: this.scrollListPayload.recordsPerPage
      }
      let url = `${this.getHostRefApi}chemicallist?only_items=1&start=${model.start}&length=${model.length}`
      if (search) url += `&search=${search}`
      hostAppApi.get(url)
        .then(response => {
          const { data, recordsFiltered } = response.data
          this.scrollListPayload.list = [...this.scrollListPayload.list, ...data]
          if (data.length) this.scrollListPayload.totalCount = recordsFiltered
          if (this.scrollListPayload.totalCount === this.scrollListPayload.list.length) this.listMaxCallLoaded = true
        }).finally(() => {
          this.scrollListPayload.loading = false
          if (this.scrollListPayload.page === 0) this.renderScrollList = true
        })
    }
  },
  beforeDestroy () {
    this.$eventBus.$off('loadList')
  }
}
</script>
